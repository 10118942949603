import { useState, useRef, useEffect, useCallback } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

const HoverableObject = ({ children, hoverOffsetY = 0.1 }) => {
    const ref = useRef();
    const originalPosition = useRef({ x: 0, y: 0, z: 0 });

    const onHover = () => {
        if (ref.current) {
            originalPosition.current = ref.current.position.clone();
            ref.current.position.y += hoverOffsetY;
        }
    };

    const onUnhover = () => {
        if (ref.current) {
            ref.current.position.copy(originalPosition.current);
        }
    };

    return (
        <group ref={ref} onPointerOver={onHover} onPointerOut={onUnhover}>
            {children}
        </group>
    );
};

const MainScene = ({ camera, orbitControlsRef }) => {
    const { scene } = useGLTF(`${process.env.PUBLIC_URL}/LandingPage.gltf`);
    const ref = useRef();
    const { gl } = useThree();
    const raycaster = useRef(new THREE.Raycaster());
    const [clickedObject, setClickedObject] = useState(null);
    const [isAnimatingCamera, setIsAnimatingCamera] = useState(false);
    const targetCameraPosition = useRef(new THREE.Vector3());
    const animationSpeed = useRef(0.05);
    const [orbitMovement, setOrbitMovement] = useState(0);

    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh && child.material && child.material.isMeshStandardMaterial) {
                child.material.metalness = 0.5;
                child.material.roughness = 0.5;
            }

            if (['LogoBtn', 'PublicationBtn', 'ResearchBtn'].includes(child.name)) {
                const parent = new THREE.Group();
                parent.add(child);
                parent.name = child.name;
                scene.add(parent);
            }
        });

        camera.position.set(-0.15, 5.5, 4);
        const degToRad = (degrees) => degrees * (Math.PI / 180);
        const rx = degToRad(-30.52);
        const ry = degToRad(0);
        const rz = degToRad(-0.1);
        const upwardTilt = degToRad(-1);
        camera.rotation.set(rx + upwardTilt, ry, rz);
        camera.fov = 62.73;
        camera.updateProjectionMatrix();
    }, [camera, scene]);

    const onCanvasClick = useCallback((event) => {
        const mouse = {
            x: (event.clientX / gl.domElement.clientWidth) * 2 - 1,
            y: -(event.clientY / gl.domElement.clientHeight) * 2 + 1
        };

        raycaster.current.setFromCamera(mouse, camera);
        const intersects = raycaster.current.intersectObjects(scene.children, true);

        if (intersects.length > 0) {
            const clicked = intersects[0].object;
            setClickedObject(clicked);
            console.log("Clicked object:", clicked);

            if (clicked.name === 'LogoBtn') {
                setOrbitMovement(0);
                window.location.reload();
            } else if (clicked.name === 'PublicationBtn') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(-2, 10, 0);
                orbitControlsRef.current.target.set(-10, 10, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'ResearchBtn') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(0, 14, 0);
                orbitControlsRef.current.target.set(10, 14, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'GoBackBtn1') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(-0.15, 5.5, 4);
                orbitControlsRef.current.target.set(-0.18, 3, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'GoBackBtn2') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(-0.15, 5.5, 4);
                orbitControlsRef.current.target.set(-0.07, 3, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'CVBtn') {
                setOrbitMovement(0);
                window.open('https://drive.google.com/file/d/1PqMgmlR6JFMNE8VP001hIVTZVpAgsEvi/view?usp=sharing');
            } else if (clicked.name === 'LinkedIn') {
                setOrbitMovement(0);
                window.open('https://www.linkedin.com/in/changsoo-jung-a83340108/');
            } else if (clicked.name === 'Github') {
                setOrbitMovement(0);
                window.open('https://github.com/cjung5');
            } else if (clicked.name === 'OrbitBtn') {
                targetCameraPosition.current.set(-0.15, 7.5, 8);
                orbitControlsRef.current.target.set(-0.17, 3, 0);
                setOrbitMovement(0.001);
                setIsAnimatingCamera(true);
            }
        }
    }, [camera, gl.domElement, scene.children, orbitControlsRef]);

    useEffect(() => {
        gl.domElement.addEventListener('click', onCanvasClick);
        return () => {
            gl.domElement.removeEventListener('click', onCanvasClick);
        };
    }, [onCanvasClick, gl.domElement]);

    useFrame(() => {
        if (isAnimatingCamera) {
            camera.position.lerp(targetCameraPosition.current, animationSpeed.current);
            if (camera.position.distanceTo(targetCameraPosition.current) < 0.1) {
                setIsAnimatingCamera(false);
            }
        }
        if (ref.current) {
            ref.current.rotation.y += orbitMovement;
        }
    });

    return <primitive object={scene} ref={ref} />;
};

const CameraController = ({ camera }) => {
    const isDragging = useRef(false);
    const lastMousePosition = useRef({ x: 0, y: 0 });

    const onMouseDown = useCallback((event) => {
        if (event.button === 0) {
            console.log("Mouse Down - Disabling OrbitControls");
            isDragging.current = true;
            lastMousePosition.current = { x: event.clientX, y: event.clientY };
        }
    }, []);

    const onMouseUp = useCallback(() => {
        isDragging.current = false;
    }, []);

    const onMouseMove = useCallback((event) => {
        if (isDragging.current) {
            const deltaX = event.clientX - lastMousePosition.current.x;
            const deltaY = event.clientY - lastMousePosition.current.y;

            const rotationSpeed = 0.005;
            camera.rotation.y -= deltaX * rotationSpeed;
            camera.rotation.x -= deltaY * rotationSpeed;

            lastMousePosition.current = { x: event.clientX, y: event.clientY };
        }
    }, [camera]);

    useEffect(() => {
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);
        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, [onMouseDown, onMouseUp, onMouseMove]);

    return null;
};

const SceneWithControls = () => {
    const { camera } = useThree();
    const orbitControlsRef = useRef();

    return (
        <>
            <MainScene camera={camera} orbitControlsRef={orbitControlsRef} />
            <CameraController camera={camera} />
            <OrbitControls 
                ref={orbitControlsRef}
                camera={camera}
                enableZoom={false}
                target={[-0.14, 3, 0]}
            />
        </>
    );
};

const LandingPage = () => (
    <Canvas style={{ width: '100vw', height: '100vh' }} pointerEvents="all">
        <SceneWithControls />
    </Canvas>
);

export default LandingPage;